/* eslint-disable react/no-danger */
import React from 'react'
import { trackEvent, getCommonEventProperties } from '@acko-tech/building-blocks.ui.acko-analytics'
import Image from 'next/image'
import { HEADER_THEME } from '@styles/theme'
import type IHeader from './interfaces/IHeader'
import styles from './globalHeader.module.scss'
import { TAP_HEADER_TABS } from './constants'
import { ToggleIcon } from './ToggleIcon'
import { Links } from './Links'
import { type Categories as ICategory } from './interfaces/Categories'
import { largeIcons } from './largeIcons'

interface ICategories {
  currentlySelectedType: {
    type: string
    typeIndex: number
  }
  currentlyActiveSection: number
  setCurrentlyActiveSection: Function
  setCurrentlySelectedType: Function
  setIsShowingLinks?: Function
  isShowingLinks?: boolean
  isHeaderVisible: boolean
  children?: any
  isDesktop?: any
  setShowProfileModal?: Function
}

export const Categories = (props: IHeader & ICategories) => {
  const {
    schema,
    currentlySelectedType,
    setCurrentlySelectedType,
    setIsShowingLinks,
    currentlyActiveSection,
    setCurrentlyActiveSection,
    isHeaderVisible,
    children,
    isDesktop,
    isShowingLinks,
    largeHeader,
    pageType,
    darkDesktop,
    theme = HEADER_THEME.LIGHT,
    setShowProfileModal,
    isLoggedIn,
  } = props
  const handleCurrentlyActiveSectionClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    cat: ICategory,
    index: number
  ) => {
    e.stopPropagation()
    e.preventDefault()
    setShowProfileModal?.(false)
    if (currentlyActiveSection === index) {
      setCurrentlyActiveSection(-1)
    } else {
      setCurrentlyActiveSection(index)
      setCurrentlySelectedType({
        type: 'Health',
        typeIndex: 0,
      })

      void trackEvent(TAP_HEADER_TABS, {
        tab_names: [cat.title],
        product: '',
        header_name: cat.title,
        redirection_url: '',
        ...getCommonEventProperties(pageType),
      })
    }
  }

  return (
    <div>
      <div className={`${children ? styles.addBlock : ''} ${children && isDesktop ? styles.largeHeader : ''}`}>
        {children}
      </div>
      <div
        className={`${styles.categoriesWrapper} ${isHeaderVisible ? styles.isHeaderVisible : ''} ${
          children ? styles.addBlock : ''
        } ${children && largeHeader ? styles.largeHeader : ''}`}>
        {schema?.categories?.map((cat, index) => (
          <React.Fragment key={cat.title + index}>
            <div
              className={`${styles.categories} ${
                currentlyActiveSection === index
                  ? `${styles.active} ${darkDesktop ? styles.darkDesktop : ''} ${
                      currentlyActiveSection === -1 ? styles[theme] : ''
                    }`
                  : ''
              }`}
              id={`categories-${index}`}>
              <div
                onClick={e => {
                  handleCurrentlyActiveSectionClick(e, cat, index)
                }}
                className={`${styles.categorySubWrapper} ${
                  currentlyActiveSection === index
                    ? `${styles.currentlyActive} ${darkDesktop ? styles.darkDesktop : ''} ${
                        currentlyActiveSection === -1 ? styles[theme] : ''
                      }`
                    : ''
                }`}>
                <span className={styles.categoryInfo}>
                  {/* mobile */}
                  {isHeaderVisible ? (
                    <span className={styles.hideOnDesktop}>
                      <Image src={cat?.icon} width={50} height={50} alt="Category Image" />
                    </span>
                  ) : null}
                  <span
                    className={`${styles.categoryTitle} ${darkDesktop ? styles.darkDesktop : ''} ${
                      currentlyActiveSection === -1 ? styles[theme] : ''
                    }`}>
                    {cat.title}
                  </span>
                </span>
                <ToggleIcon
                  isPurple={darkDesktop || (theme !== HEADER_THEME.LIGHT && currentlyActiveSection === -1)}
                  isActive={currentlyActiveSection === index}
                />
              </div>
              <div
                className={`${styles.categoryDetails} ${
                  currentlyActiveSection === index ? styles.shouldShowCategoryDetails : ''
                }`}>
                {/* desktop */}
                {currentlyActiveSection !== -1 ? (
                  <span className={styles.catIcon}>
                    <Image src={cat?.icon} width={50} height={50} alt="Section Image" />
                  </span>
                ) : null}
                <span
                  className={`${styles.innerCategoryTitle} ${darkDesktop ? styles.darkDesktop : ''} ${
                    currentlyActiveSection === -1 ? styles[theme] : ''
                  }`}>
                  {cat.title}
                </span>
                <span className={styles.categoryDescription}>{cat.description}</span>
              </div>
              <div
                className={`${styles.accordionWrapper} ${
                  currentlyActiveSection === index ? styles.accordionVisible : ''
                }`}>
                <div className={`${styles.sections} ${largeHeader ? styles.largeHeader : ''}`}>
                  {cat.sections.map((section, ind) => (
                    <div key={`${cat.title}-${section.title}-${ind}`}>
                      <div className={styles.sectionTitle}>{section.title}</div>
                      {section.types.map((type, typeIndex) => (
                        <div
                          key={`${cat.title}-${type.title}-${typeIndex}`}
                          onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setCurrentlySelectedType({
                              type: type.title,
                              typeIndex,
                            })
                            if (!isDesktop && setIsShowingLinks) {
                              setIsShowingLinks(!isShowingLinks)
                            }
                            void trackEvent(TAP_HEADER_TABS, {
                              tab_names: [cat.title],
                              product: type.title || '',
                              header_name: type.title || '',
                              redirection_url: (!type.links || type.links.length === 0) ? type.href || '' : '',
                              ...getCommonEventProperties(pageType),
                            })
                            if (!type.links || type.links.length === 0) {
                              setCurrentlySelectedType({
                                type: '',
                                typeIndex: -1,
                              })
                              setCurrentlyActiveSection(-1)
                              if (setIsShowingLinks) setIsShowingLinks(false)
                              window.open(type.href || '', '_blank')
                            }
                          }}
                          onMouseOver={() => {
                            setCurrentlySelectedType({
                              type: type.title,
                              typeIndex,
                            })
                          }}
                          onFocus={() => undefined}
                          className={`${styles.typeTitle} ${
                            currentlySelectedType?.type === type?.title ? `${styles.active}` : ''
                          }`}>
                          {type.links && type.links.length > 0 ? (
                            <>
                              <span>{type.title}</span>
                              <span
                                className={styles.hideOnMobile}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    schema?.categories[currentlyActiveSection] &&
                                    cat?.title === schema?.categories[currentlyActiveSection]?.title &&
                                    currentlySelectedType?.typeIndex === typeIndex &&
                                    currentlySelectedType?.type === type?.title
                                      ? `<img src="${largeIcons.titleImage}" alt="Title Image" />`
                                      : '',
                                }}
                              />

                              {currentlyActiveSection !== -1 ? (
                                <span className={styles.hideOnDesktop}>
                                  <Image src={largeIcons.titleImageDark} alt="Title Image" width={7} height={14} />
                                </span>
                              ) : null}

                              <Links
                                setIsShowingLinks={setIsShowingLinks}
                                isShowingLinks={isShowingLinks}
                                show={
                                  schema?.categories[currentlyActiveSection] &&
                                  cat?.title === schema?.categories[currentlyActiveSection]?.title &&
                                  currentlySelectedType?.typeIndex === typeIndex &&
                                  currentlySelectedType?.type === type?.title
                                }
                                links={type?.links}
                                category={cat?.title}
                                product={type?.title}
                                pageType={pageType}
                                largeHeader={largeHeader}
                                currentlyActiveSection={currentlyActiveSection}
                                isLoggedIn={isLoggedIn}
                              />
                            </>
                          ) : (
                            <span className={type.blue ? styles.blue : ''}>{type?.title}</span>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div className={`${styles.imageWrapper} ${currentlyActiveSection === -1 && `${styles.none}`}`}>
                {currentlyActiveSection !== -1 ? (
                  <Image
                    src="https://acko-cms.ackoassets.com/Global_Nav_Header_QR_17a9abf7a0.png"
                    alt="header image"
                    width={370}
                    height={400}
                  />
                ) : null}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
