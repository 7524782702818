/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-curly-brace-presence */

'use client'

import React, { Suspense, useEffect, useState } from 'react'
import Image from 'next/image'
import GlobalHeader from '@components/GlobalHeader'
import { useUserContext } from '@context/UserContext/UserContenxt'
import { getPageType } from 'src/helpers/commonHelpers'
import { useUserData } from '@utils/userUserData'
import { HEADER_THEME } from '@styles/theme'
import { Lob } from '@enums/Lob'
import Cookies from 'js-cookie'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { OLD_LOGIN_PATH } from '@components/GlobalHeader/constants'
import { ACKO_LOGO_HORIZONTAL_GRADIENT_DARK, ACKO_LOGO_HORIZONTAL_GRADIENT_WHITE } from '@components/utils/ackoLogoUrls'
import styles from './productNav.module.scss'

const lobSemNavBarRequired = {
  [Lob.TRAVEL]: true,
}

const HeaderDesktopAndMweb = ({
  isSemPage = false,
  headerLinks,
  darkDesktop = false,
  darkMobile = false,
  isHomeWrapper = false,
  theme = HEADER_THEME.LIGHT,
  lob = null,
  loginBaseUrl = '',
  data,
}) => {
  const content = data?.data?.attributes

  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined)
  const userDataValue = useUserData(isLoggedIn)
  const { setUserLoggedIn } = useUserContext()
  const isNewLogin = useFeatureIsOn('new-login-flow-homepage') ?? false

  useEffect(() => {
    const isUserLoggedIn = !!Cookies.get('user_id')
    setIsLoggedIn(isUserLoggedIn)
    setUserLoggedIn(isUserLoggedIn || false)
  }, [])

  return (
    <Suspense fallback={<div className={styles.loaderStyles} />}>
      {isSemPage && lob && lobSemNavBarRequired[lob] ? (
        <div className={styles.semNavbar}>
          <Image
            width={100}
            height={24}
            src={content?.theme !== 'light' ? ACKO_LOGO_HORIZONTAL_GRADIENT_DARK : ACKO_LOGO_HORIZONTAL_GRADIENT_WHITE}
            alt="Acko Company Logo"
            priority
          />
        </div>
      ) : (
        <GlobalHeader
          schema={headerLinks}
          darkDesktop={darkDesktop}
          darkMobile={darkMobile}
          isLoggedIn={isLoggedIn}
          userData={userDataValue}
          largeHeader
          pageType={getPageType(isSemPage)}
          isHomeWrapper={isHomeWrapper}
          theme={theme}
          loginBaseUrl={isNewLogin ? loginBaseUrl : OLD_LOGIN_PATH}
        />
      )}
    </Suspense>
  )
}

export default HeaderDesktopAndMweb
