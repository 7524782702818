import { range } from '@components/Ackodrive/common/utils'

export const getPageType = (isSemPage = false) => (isSemPage ? 'sem' : 'seo')

export const getStartForPagination = (pageNumber, limit) => (pageNumber - 1) * limit

export const fetchPageNumbers = (pages, currPage) => {
  const totalPages = pages
  const currentPage = currPage
  const pageNeighbours = 1
  const totalNumbers = 4
  const totalBlocks = totalNumbers

  if (totalPages > totalBlocks) {
    const startPage = Math.max(1, currentPage - pageNeighbours)
    const endPage = Math.min(totalPages, currentPage + pageNeighbours)
    let pages = range(startPage, endPage + 1)
    const hasLeftSpill = startPage > 1
    const hasRightSpill = totalPages - endPage > 0
    const spillOffset = totalNumbers - (pages.length + 1)

    switch (true) {
      case hasLeftSpill && !hasRightSpill: {
        const extraPages = range(startPage - spillOffset, endPage + 1)
        pages = [...extraPages]
        break
      }
      case !hasLeftSpill && hasRightSpill: {
        const extraPages = range(endPage + 1, endPage + spillOffset + 1)
        pages = [...pages, ...extraPages]
        break
      }
      case hasLeftSpill && hasRightSpill:
      default: {
        pages = [...pages]
        break
      }
    }

    return [...pages]
  }
  const rangeDisplay = range(1, totalPages + 1)
  return rangeDisplay
}

export const getBreadcrumb = blogPageResponse => {
  let current = blogPageResponse[0]?.attributes?.topics

  const hierarchy: any[] = []

  while (current?.data) {
    hierarchy.unshift({
      value: current?.data?.attributes?.topicName,
      url: `/articles/${current?.data?.attributes?.url}/`,
    })
    current = current?.data?.attributes?.parent
  }
  return hierarchy
}
